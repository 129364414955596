import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"

const NotFoundPage = () => {
  Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </Layout>
  )
}

export default NotFoundPage
